@font-face {
  font-family: 'Nunito-Regular';
  src: url('../assets/fonts/Nunito/Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-Medium';
  src: url('../assets/fonts/Nunito/Nunito-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-SemiBold';
  src: url('../assets/fonts/Nunito/Nunito-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-Bold';
  src: url('../assets/fonts/Nunito/Nunito-Bold.ttf') format('truetype');
}
